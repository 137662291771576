export function getChangedFields(
  item: Record<string, string | number | null>,
  initialItem: Record<string, string | number | null>
) {
  const changedFields: Record<string, string | number | null> = {};
  for (const itemKey in item) {
    if (initialItem[itemKey] !== item[itemKey]) {
      changedFields[itemKey] = item[itemKey];
    }
  }
  return changedFields;
}
